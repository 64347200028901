<template>
  <layout>
    <div id="main-container ">
      <div class="contentWrapper  mb-96 shadow-lg">
        <ul class="articleList   font-black text-black">
          <li>
            <a href="#"
              ><i class="icon-article-doc"></i
              ><span>Where can I use my NayaPay Visa debit card?</span></a
            >
          </li>
          <li>
            <a href="#"
              ><i class="icon-article-doc"></i
              ><span>Can I shop online with my Visa debit card?</span></a
            >
          </li>
          <li>
            <a href="#"
              ><i class="icon-article-doc"></i
              ><span
                >Can I use my Visa debit card for international payments?</span
              ></a
            >
          </li>

          <li>
            <a href="#"
              ><i class="icon-article-doc"></i
              ><span>What’s a Visa Virtual debit card? </span></a
            >
          </li>
          <li>
            <a href="#"
              ><i class="icon-article-doc"></i
              ><span
                >Can I withdraw cash through my Visa debit card?</span
              ></a
            >
          </li>
          <li>
            <a href="#"
              ><i class="icon-article-doc"></i
              ><span
                >My card is expiring or has expired. What do I do?</span
              ></a
            >
          </li>
        </ul>
      </div>
    </div>
  </layout>
</template>

<script>
import layout from "../../../components/Categories/layout.vue";
export default {
  components: { layout },
};
</script>

<style scoped>
li {
  border-bottom: #858585 1px solid;
}
.contentWrapper {
  background: #fff;
  border: 1px solid #e9e5e5;

  min-height: 460px;
  padding: 50px 46px;
  position: relative;
}

#main-container{
    margin-bottom: 170px;
}

.articleList {
  font-size: 3rem;
  font-weight: 600;
}
</style>
